import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../../Config";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { AuthContext } from "../../../store/context/AuthProvider";
import "../login/login.css";
import { toast } from "react-hot-toast";
import { API_PATH } from "../../../constant/apiPath";

import validator from "validator";
import {
  Box,
  Button,
  CircularProgress,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import BackdropLoader from "../../../components/BackDropLoader/BackdropLoader";
import urlResolver from "../../../utils/urlResolver";

const Resetpassword = () => {
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { id } = useParams();
  // const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  // const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [newPassworderror, setNewPassworderror] = useState("");
  const [confirmPassworderror, setConfirmPassworderror] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confrmpass, setConfrmpass] = useState("");
  const [backDropLoader, setBackDropLoader] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const validatePassword = (e) => {
    setNewPass(e.target.value);
    var pssword = e.target.value;
    if (
      validator.isStrongPassword(pssword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setNewPassworderror("");
    } else {
      if (validator.isEmpty(pssword)) {
        setNewPassworderror("");
      } else {
        setNewPassworderror(
          "Passwords must be at least 8 characters. Passwords must have at least one special character. Passwords must have at least one digit (0-9). Passwords must have at least one uppercase (A-Z)."
        );
      }
    }
  };
  const validateConfirmPassword = (e) => {
    var validated = false;
    setConfrmpass(e.target.value);
    var pssword = e.target.value;
    if (validator.isEmpty(pssword)) {
      setConfirmPassworderror("");
    } else {
      if (newPass !== pssword) {
        setConfirmPassworderror("Password Not match");
      } else {
        setConfirmPassworderror("");
      }
    }
  };

  const onUserResetPassword = (e) => {
    // if (password.length < 8) {
    //   setPasswordError("Password should be at least 8 characters long");
    // } else if (!/[A-Z]/.test(password)) {
    //   setPasswordError("Password should contain at least one uppercase letter");
    // } else if (!/[a-z]/.test(password)) {
    //   setPasswordError("Password should contain at least one lowercase letter");
    // } else if (!/\d/.test(password)) {
    //   setPasswordError("Password should contain at least one number");
    // } else {
    //   // Password is valid, proceed with form submission or further processing
    //   console.log("Password is valid");
    //   setLoading(true);
    //   if (password !== confirmPassword) {
    //     setLoading(false);
    //     toast.error("Password and confirm password are not same");
    //   } else {
    const body = {
      password: newPass,
      confirmPassword: confrmpass,
      id: id ?? "",
    };
    axios
      .post(`${baseUrl}${API_PATH.RESET_PASSWORD}`, body)
      .then((res) => {
        setLoading(false);
        if (res.data.IsSuccess) {
          toast.success(res.data.Message.Message);
          navigate(Routeconstant.LOGIN);
        } else {
          toast.error(res.data.Message.Message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    try {
      fetch(`${baseUrl}${API_PATH.RESETPASSWORD_LINKACTIVATE}${id}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.IsSuccess) {
            setBackDropLoader(false);
          } else {
            toast.error(`${data?.Message?.Message}`);
            setBackDropLoader(false);
            setTimeout(() => {
              navigate(Routeconstant?.LOGIN);
            }, 0);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(`Link expired!!!`);
          setTimeout(() => {
            navigate(Routeconstant?.LOGIN);
          }, 2000);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Grid
      container
      direction={{
        lg: "row",
        md: "row",
        sm: "column-reverse",
        xs: "column-reverse",
      }}
      sx={{
        flexWrap: "inherit",
      }}
    >
      <BackdropLoader open={backDropLoader} />
      <Grid
        item
        lg={5}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "68%",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              marginBottom: "2%",
            }}
          >
            Reset Password
          </Typography>
          <form>
            <FormGroup>
              <>
                <FormLabel
                  id="Email"
                  sx={{
                    fontSize: "80%",
                    margin: "0",
                  }}
                >
                  Password
                </FormLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="password"
                  minLength="8"
                  name="password"
                  autoComplete="off"
                  className="form-control"
                  placeholder="New Password"
                  value={newPass}
                  onChange={validatePassword}
                  sx={{
                    marginBottom: "3%",
                  }}
                />
                <span>
                  {newPassworderror === "" ? null : (
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        width: "28rem",
                      }}
                    >
                      {newPassworderror}
                    </div>
                  )}
                </span>
              </>
              <>
                <FormLabel
                  id="Email"
                  sx={{
                    fontSize: "80%",
                    margin: "0",
                  }}
                >
                  Confirm Password
                </FormLabel>
                <TextField
                  fullWidth
                  size="small"
                  type="password"
                  autoComplete="off"
                  id="confPassword"
                  minLength="8"
                  name="Confirm password"
                  className="form-control mt-1"
                  placeholder="Confirm Password"
                  value={confrmpass}
                  onChange={validateConfirmPassword}
                  sx={{
                    marginBottom: "3%",
                  }}
                />
                <span>
                  {confirmPassworderror === "" ? null : (
                    <div
                      style={{
                        color: "red",
                        fontSize: "16px",
                      }}
                    >
                      {confirmPassworderror}
                    </div>
                  )}
                </span>
              </>

              <Button
                variant="contained"
                color="primary"
                // type="submit"
                onClick={(e) => {
                  onUserResetPassword(e);
                }}
              >
                {loading ? (
                  <CircularProgress
                    size="2rem"
                    style={{ color: "var(--color-miscellaneous-4)" }}
                  />
                ) : (
                  "Reset"
                )}
              </Button>
            </FormGroup>
          </form>
        </Box>
      </Grid>
      <Grid
        item
        lg={7}
        md={12}
        sm={12}
        xs={12}
        className="second-section"
        key={1}
      >
        <Grid container className="login-background-opacity ">
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={urlResolver(`assets/BROKERMANWhite.png`)}
                style={{ width: "26rem", height: "8rem" }}
              />
            </Grid>
          </Grid>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Resetpassword;
