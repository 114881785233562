import React from "react";
import "../../screens/shipment/shipment.css";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const SortModalShipment = ({
  modalShipment,
  setModalShipment,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  status,
  getShipmentCollection,
  setCheckActive,
  setLoadingOnSearch,
  sortFilterObj,
  setCurrentPage
}) => {
  const handleClose = () => setModalShipment(false);
  const navigate = useNavigate();
  return (
    <Dialog
      open={modalShipment}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            subheader={
              <div className="d-flex justify-content-between">
                <Button
                  className="font-change"
                  color={"primary"}
                  onClick={() => {
                    sortFilterObj.current = {
                      sortColumn: "",
                      sortDirection: "Asc",
                    };
                    setLoadingOnSearch(true);
                    setModalShipment(false);
                    setSortColumn("");
                    setSortDirection("Asc");
                    getShipmentCollection(0, status);
                  }}
                >
                  <KeyboardBackspaceIcon /> Back
                </Button>
                <Button
                  className="font-change"
                  color={"primary"}
                  onClick={() => {
                    sortFilterObj.current = {
                      sortColumn: "",
                      sortDirection: "Asc",
                    };
                    setSortColumn("");
                    setSortDirection("Asc");
                    setCurrentPage(0)
                  }}
                >
                  Reset
                </Button>
              </div>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={4} xs={4}>
                <Typography
                  align="center"
                  className={
                    sortColumn === "JS_ETADate"
                      ? "modal-card modal-sort-color px-1 py-1 font-change"
                      : "modal-card px-1 py-1 font-change"
                  }
                  onClick={() => {
                    setSortColumn("JS_ETADate");
                    sortFilterObj.current = {
                      sortColumn: "JS_ETADate",
                      sortDirection: sortFilterObj?.current?.sortDirection,
                    };
                  }}
                >
                  ETA
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={4}>
                <Typography
                  align="center"
                  className={
                    sortColumn === "JS_ETDDate"
                      ? "modal-card modal-sort-color px-1 py-1 font-change"
                      : "modal-card px-1 py-1 font-change"
                  }
                  onClick={() => {
                    setSortColumn("JS_ETDDate");
                    sortFilterObj.current = {
                      sortColumn: "JS_ETDDate",
                      sortDirection: sortFilterObj?.current?.sortDirection,
                    };
                  }}
                >
                  ETD
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={4}>
                <Typography
                  align="center"
                  className={
                    sortColumn === "JS_ATADate"
                      ? "modal-card modal-sort-color px-1 py-1 font-change"
                      : "modal-card px-1 py-1 font-change"
                  }
                  onClick={() => {
                    setSortColumn("JS_ATADate");
                    sortFilterObj.current = {
                      sortColumn: "JS_ATADate",
                      sortDirection: sortFilterObj?.current?.sortDirection,
                    };
                  }}
                >
                  ATA
                </Typography>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={4}>
                <Typography
                  align="center"
                  className={
                    sortColumn === "JS_ATDDate"
                      ? "modal-card modal-sort-color px-1 py-1 font-change"
                      : "modal-card px-1 py-1 font-change"
                  }
                  onClick={() => {
                    setSortColumn("JS_ATDDate");
                    sortFilterObj.current = {
                      sortColumn: "JS_ATDDate",
                      sortDirection: sortFilterObj?.current?.sortDirection,
                    };
                  }}
                >
                  ATD
                </Typography>
              </Grid>
            </Grid>

            {sortColumn === "" ? null : (
              <>
                <Divider
                  className="my-3"
                  sx={{ backgroundColor: "var(--color-miscellaneous-1)" }}
                />
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid item lg={2} md={2} sm={4} xs={4}>
                    <Typography
                      align="center"
                      className={
                        sortDirection === "Asc"
                          ? "modal-card modal-sort-color px-1 py-1 font-change"
                          : "modal-card px-1 py-1 font-change"
                      }
                      onClick={() => {
                        setSortDirection("Asc");
                        sortFilterObj.current = {
                          sortColumn: sortFilterObj?.current?.sortColumn ?? "",
                          sortDirection: "Asc",
                        };
                      }}
                    >
                      Ascending
                    </Typography>
                  </Grid>
                  <Grid item lg={2} md={2} sm={4} xs={4}>
                    <Typography
                      align="center"
                      className={
                        sortDirection === "Desc"
                          ? "modal-card modal-sort-color px-1 py-1 font-change"
                          : "modal-card px-1 py-1 font-change"
                      }
                      onClick={() => {
                        setSortDirection("Desc");
                        sortFilterObj.current = {
                          sortColumn: sortFilterObj?.current?.sortColumn ?? "",
                          sortDirection: "Desc",
                        };
                      }}
                    >
                      Descending
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </CardContent>

          <CardActions className="d-flex justify-content-end">
            <Button
              className="font-change"
              title="Submit"
              onClick={() => {
                navigate(Routeconstant.SHIPMENT_LISTING, { replace: true });
                setCheckActive(null);
                getShipmentCollection(0, status);
                setModalShipment(false);
                setLoadingOnSearch(true);
                setCurrentPage(0)
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default SortModalShipment;
