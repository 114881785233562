import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import orderInstance from "../../api/orderInterceptor";
import { API_PATH } from "../../constant/apiPath";
import { useState } from "react";
const OrgDetails = ({ selectedOrg, setSelectedOrg }) => {
  const [orgs, setOrgs] = useState([]);
  const [orgDetails, setOrgDetails] = useState("");
  const orgCode = (search) => {
    const body = {
      Search: search,
      Code: "",
      FullName: "",
    };
    orderInstance
      .post(API_PATH.GET_ORGS, body)
      .then((res) => {
        setOrgs(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const getOrgDetails = () => {
    orderInstance
      .get(`${API_PATH.GET_DETAILS}${selectedOrg?.OrgCode}`)
      .then((res) => {
        setOrgDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getOrgDetails();
  }, [selectedOrg]);

  // console.log("ORG CODES", orgDetails);
  return (
    <>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="overline" color={"primary"}>
              Organisation Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  freeSolo
                  id="free-solo-2-demo"
                  options={orgs}
                  getOptionLabel={(option) => option.OrgCode}
                  value={selectedOrg}
                  onChange={(e, val) => {
                    setSelectedOrg(val);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organisation Code"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      onChange={(event) => {
                        if (event.target.value.length >= 3) {
                          orgCode(event.target.value);
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  defaultValue=" "
                  size="small"
                  label="Organisation Name"
                  value={selectedOrg?.OrgName}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" color={"primary"}>
              Buyer
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {orgDetails?.BuyerCollection === undefined ? (
              "Please select an organisation."
            ) : orgDetails?.BuyerCollection === null ? (
              "No buyer present. Please proceed to add."
            ) : orgDetails?.BuyerCollection.length ? (
              <>
                <table>
                  <thead>
                    <tr>
                      <td className="col-6">Buyer Name</td>
                      <td className="col-6">Buyer Code</td>
                    </tr>
                  </thead>
                  <tbody>
                    {orgDetails?.BuyerCollection.map((indvDetail) => {
                      return (
                        <tr>
                          <td className="col-6">{indvDetail?.BuyerName}</td>
                          <td className="col-6">{indvDetail?.BuyerCode}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <p>No buyer present. Please proceed to add.</p>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" color={"primary"}>
              Supplier
            </Typography>
            <Grid item xs={12}>
              {orgDetails?.SupplierCollection === undefined ? (
                "Please select an organisation."
              ) : orgDetails?.SupplierCollection === null ||
                orgDetails?.SupplierCollection.length === 0 ? (
                "No supplier present. Please proceed to add."
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td className="col-6">Supplier Name</td>
                        <td className="col-6">Supplier Code</td>
                      </tr>
                    </thead>
                    <tbody>
                      {orgDetails?.SupplierCollection.map((indvDetail) => {
                        return (
                          <tr>
                            <td className="col-6">
                              {indvDetail?.SupplierName}
                            </td>
                            <td className="col-6">
                              {indvDetail?.SupplierCode}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline" color={"primary"}>
              Agent
            </Typography>
            <Grid item xs={12}>
              {orgDetails?.AgentCollection === undefined ? (
                "Please select an organisation."
              ) : orgDetails?.AgentCollection === null ||
                orgDetails?.AgentCollection?.length === 0 ? (
                "No agent present. Please proceed to add."
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td className="col-4">Agent Name</td>
                        <td className="col-4">Agent Code</td>
                        <td className="col-4">Agent Type</td>
                      </tr>
                    </thead>
                    <tbody>
                      {orgDetails?.AgentCollection.map((indvDetail) => {
                        return (
                          <tr>
                            <td className="col-6">{indvDetail?.AgentName}</td>
                            <td className="col-6">{indvDetail?.AgentCode}</td>
                            <td className="col-6">{indvDetail?.AgentType}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OrgDetails;
