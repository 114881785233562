import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";
import NodatafoundComp from "../NodatafoundComp";

export default function BarChart({
  shipmentpopup,
  filterOptions,
  setLoadingobj,
  loadingobj,
}) {
  const colors = ["#236C9C", "#0065E3", "#6190CB", "#0A2D44", "#D0DEF0"];
  const [barData, setBarData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const context = useContext(AuthContext);
  const getBarChart = () => {
    setLoadingobj({ ...loadingobj, barChart: true });
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions?.filterMode
        ? filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpDirection: "",
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
    };
    instance
      .post(API_PATH.GET_ANALYTICS_SHIPMENTCOUNTONMODE, body)
      .then((res) => {
        setLoadingobj({ ...loadingobj, barChart: false });

        if (res?.data?.length) {
          setNoDataFound(false);
          setBarData(res.data);
          // Separate items with non-null ContainerMode and null ContainerMode
          const withContainerMode = res?.data[0].ContainerCountModeL?.filter(
            (item) => item.ContainerMode !== null
          );
          const withoutContainerMode = res?.data[0].ContainerCountModeL?.filter(
            (item) => item.ContainerMode === null
          );
          // Concatenate the two arrays with items with null ContainerMode at the end
          const reorderedData = [...withContainerMode, ...withoutContainerMode];
          if (reorderedData) {
            let label = [];
            let tempData = [];
            let tempArr = [];
            reorderedData.map((lab, index) => {
              label.push(lab?.ContainerMode ?? "N/A");
              tempArr.push(lab.Count);
            });
            tempData.push({
              data: tempArr,
              backgroundColor: colors,
              label: "Count ",
              barPercentage: 1,
            });
            setLabels(label);
            setFinalData(tempData);
          }
        } else {
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        setLoadingobj({ ...loadingobj, barChart: false });
        console.log(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getBarChart();
  }, [filterOptions]);

  let data = {
    labels: shipmentpopup ? labels : labels.slice(0, 4),
    datasets: finalData,
  };
  return (
    <>
      {nodataFound ? (
        <NodatafoundComp />
      ) : (
        <div
          style={{
            height: "90%",
            width:
              shipmentpopup &&
              finalData[0]?.data.length >= 4 &&
              `${13 * finalData[0]?.data.length}em`,
          }}
        >
          <Bar
            style={{
              minHeight: shipmentpopup && "500px",
              maxHeight: "90%",
            }}
            data={data}
            options={{
              animation: !shipmentpopup
                ? false
                : {
                    x: {
                      duration: 1000,
                      from: 3000,
                    },
                    y: {
                      duration: 1000,
                      from: 3000,
                    },
                  },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: false, // Hide legend
                tooltip: {
                  yAlign: "bottom",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}
