import { customerName } from "../Config";

let permission = {
  financesAccess: true,
  agingAccess: true,
  outstandingAccess: true,
  Milestone: true,
  EtaAndEtd: true,
  CarrierAnalysis: true,
  KPIAnalysis: true,
  MailIcon: true,
  whatsapp: true,
  exportToExcel : true,
  Shipment: {
    Card: {
      cardheadinformation: false,
    },
  },
  Dashboard: {
    etdetd: true,
    cssPermision: false,
  },
};
const accessGiven = () => {
  switch (customerName) {
    case "SFLInsight":
   
      return permission;

    case "Brokeman":
      permission.financesAccess = false;
      permission.EtaAndEtd = true;
      permission.agingAccess = false;
      permission.outstandingAccess = false;
      permission.Milestone = false; //in case of brokeman milestone depends on a condition for that this is marked false
      permission.CarrierAnalysis = false;
      permission.KPIAnalysis = false;
      permission.MailIcon = false;
      permission.whatsapp = false;
      permission.Dashboard.cssPermision = true;
      permission.Shipment.Card.cardheadinformation = true;
      return permission;

    default:
      return permission;
  }
};
export default accessGiven;

