import React from "react";
import { useEffect, useContext } from "react";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { useState } from "react";
import { DateFormat } from "../../utils/moment";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import urlResolver from "../../utils/urlResolver";
import CustomTooltip from "../../utils/CustomTooltip";
import { toast } from "react-hot-toast";
import { clsx } from "clsx";

export default function PendingRms() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [isloading, setisLoading] = useState(true);

  const [cardClicked, setCardClicked] = useState(null);
  const [favoriteShipment, setFavoriteShipment] = useState([]);
  useEffect(() => {
    getFavoriteShipment();
  }, []);
  const markFavourite = (shipmentId, isFavourite) => {
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${shipmentId}&IsFavourite=${isFavourite}`
      )
      .then((res) => {
        toast.success(res?.message?.Message ?? "");
        getFavoriteShipment();
      })
      .catch((err) => {
        toast.success(err ?? "");
      })
      .finally(() => {
        setCardClicked(null);
      });
  };
  const getFavoriteShipment = () => {
    setisLoading(true);
    const body = {
      ShipmentId: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_FAVORITESHIPMENT, body)
      .then((res) => {
        setisLoading(false);
        setFavoriteShipment(res?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const transportImg = (locate) => {
    if (locate === "SEA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/ship.svg")} />
      );
    } else if (locate === "AIR") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/Air.svg")} />
      );
    } else if (locate === "ROA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/road.svg")} />
      );
    } else if (locate === "RAI") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/rail.svg")} />
      );
    } else if (locate === "COU") {
      return (
        <img
          className="img-ship-cargo"
          src={urlResolver("assets/courier.svg")}
        />
      );
    }
  };

  return (
    <div className="favorite-shipment-container">
      <div
        className="heading-filterbutton-container"
        style={{ paddingRight: "20px" }}
      >
        <div
          className="heading-shipment"
          style={{ padding: " 0px 20px 0px 0px" }}
        >
          Favorites <CustomTooltip of="Dashboard" forKey="Favorites" />
        </div>
      </div>
      <div className="shipment-data">
        {favoriteShipment.length ? (
          <>
            {favoriteShipment.map((item, index) => {
              return (
                <div className="card data-card" key={index}>
                  <div className="card-body-container">
                    <div
                      className="status-shipmentnumber-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="d-flex align-items-center"
                        style={{
                          position: "absolute",
                          left: " -25px",
                          top: "-26px",
                        }}
                      >
                        <div className="status-container">
                          {item?.ShipmentStatus}
                        </div>
                        <img
                          style={{ cursor: "pointer" }}
                          className={clsx(
                            "img-star ps-2",
                            index === cardClicked && "flash"
                          )}
                          src={urlResolver("assets/coloredStar.svg")}
                          onClick={() => {
                            if (index !== cardClicked) {
                              setCardClicked(index);
                              markFavourite(item?.ShipmentId, false);
                            }
                          }}
                        />
                      </div>
                      <div className="status-img-container">
                        <div
                          className="shipment-image"
                          style={{ position: "absolute", right: "-28px" }}
                        >
                          {transportImg(item.TransportMode)}
                        </div>
                        <div
                          className="shipmentID-container"
                          onClick={() => {
                            context.setShipmentId(item?.ShipmentId);
                            context.setGridViewShipment(false);
                            navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                              state: {
                                ShipmentId: item.ShipmentId,
                                data: item,
                              },
                            });
                          }}
                        >
                          <span
                            style={{
                              color: "var(--color-primary)",
                            }}
                          >
                            {item?.ShipmentId}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="logistics-container"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div
                        className="origin-etd-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <p className="check-font">Origin</p>
                          <p className="check-font-list">
                            {item?.OriginPortName ?? "N/A"}
                            {`(${item?.OriginPortCode ?? ""})`}
                          </p>
                        </div>
                        <div>
                          <p className="check-font">ETD</p>
                          <p className="check-font-list">
                            {DateFormat(item?.ETDDate ?? null)}
                          </p>
                        </div>
                      </div>
                      <div
                        className="destination-eta-container"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div>
                          <p className="check-font">Destination</p>
                          <p className="check-font-list">
                            {item?.DestinationPortName ?? "N/A"}
                            {`(${item?.DestinationPortCode ?? ""})`}
                          </p>
                        </div>
                        <div>
                          <p className="check-font">ETA</p>
                          <p className="check-font-list">
                            {DateFormat(item?.ETADate)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <div
            className="font-change"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40rem",
              fontSize: "var(--fs-mid)",
              color: "var(--color-miscellaneous-2)",
            }}
          >
            No data found!
          </div>
        )}
      </div>
    </div>
  );
}
