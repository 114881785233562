const ENV = "PRD";
// const ENV = "";
let basename = "";
let marginRms = false;
let baseUrl = "";
let baseOrderUrl = "";
let LOCAL_STORAGE_KEY = "";
let customerName = "Brokeman";
if (ENV === "PRD") {
  basename = "";
  baseUrl = "https://my.broekmanlogistics.com/InsightV2Api/";
  // https://my.broekmanlogistics.com/
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
} else {
  basename = "dev";
  baseUrl = "https://my.broekmanlogistics.com/InsightV2ApiDev/";
  // https://my.broekmanlogistics.com/dev
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
}

export { basename, baseUrl, baseOrderUrl, LOCAL_STORAGE_KEY, customerName,marginRms };
// "start": "set PORT=4001 &&  react-scripts start", set port 4001 for order API's
