import { useState, useEffect } from "react";
import { styled, Button } from "@mui/material";
import React from "react";
import { basename } from "../../Config";
import { AuthContext } from "../../store/context/AuthProvider";
import urlResolver from "../../utils/urlResolver";
import { useLocation } from "react-router-dom";
const RmsButton = styled(Button)(({ theme }) => ({
  // Custom styles for the button
}));

const CreateEnquiry = (props) => {
  const context = React.useContext(AuthContext);
  const location = useLocation();
  const selectedData = props?.selectedData ?? 0;
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (index, item) => {
    setActiveButton(index);
    context.setButtonActive({ Code: item.Code, Description: item.Description });
    // props.setFormdropData1({
    //   transportType: "",
    //   movementType: "",
    //   cargoType: "",
    //   incoType:""
    // });
    // props.setFormOriginData({
    //   originCountry: null,
    //   originPort: null,
    //   originPin: "",
    //   destCountry: null,
    //   destPort: null,
    //   destPin: "",
    //   remarks: ""
    // });
    // console.log("context.buttonActive", context.buttonActive);
  };

  useEffect(() => {
    let checkchange = false;
    props?.data?.forEach((element, index) => {
      if (element?.Description === selectedData) {
        setActiveButton(index);
        checkchange = true;
      }
    });
    if (!checkchange) {
      setActiveButton(0);
    }
  }, [selectedData]);

  return (
    <div
      className=" d-flex justify-content-around align-item-center"
      style={{ width: "100%", flexWrap: "wrap" }}
    >
      {props.data.map((item, index) => {
        return (
          <RmsButton
            key={index}
            className="font-shipment"
            sx={{
              backgroundColor:
                activeButton === index ? props.bluecolor : props.widecolor,
              color: activeButton === index ? props.widecolor : props.bluecolor,
              // Additional styles for the button
              minWidth: "100px",
              height: "50px",
              marginLeft: "15px",
              marginTop: "5px",

              borderRadius: "48px",
            }}
            onClick={() => {
              if (location.pathname.split("/").at(-1) === "enquiry") {
                handleButtonClick(index, item);
              }
            }}
          >
            <span style={{ fontSize: "var(--fs-lg)" }}>{item.Description}</span>
            {activeButton === index ? (
              <img
                src={urlResolver(`assets/${item.blueimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            ) : (
              <img
                src={urlResolver(`assets/${item.wideimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            )}
          </RmsButton>
        );
      })}
    </div>
  );
};

export default CreateEnquiry;
