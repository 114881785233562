import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import OrgDetails from "./OrgDetails";
import EditDetaisl from "./EditDetaisl";
import { useState } from "react";
import orderInstance from "../../api/orderInterceptor";
import { API_PATH } from "../../constant/apiPath";

const steps = ["Details", "Edit"];

export default function AdminSettings() {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [data, setData] = useState({
    BuyerCollection: [
      {
        BuyerCode: "",
        BuyerName: "",
        IsBuyer: true,
      },
    ],
    SupplierCollection: [
      {
        SupplierCode: "",
        SupplierName: "",
        IsSupplier: true,
      },
    ],
    AgentCollection: [
      {
        AgentCode: "",
        AgentName: "",
        AgentType: "",
        IsAgent: true,
      },
    ],
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const updateDetails = () => {
    const body = {
      OrgCode: selectedOrg?.OrgCode,
      OrgName: "",
      IsConsignee: true,
      IsConsignor: false,
      IsForwarder: false,
      ...data,
    };
    orderInstance
      .put(API_PATH.UPDATE_ORG_DETAILS, body)
      .then((res) => {
        // setOrgs(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  return (
    <>
      {/* <Grid container>
        <Grid item>
          <div>
            <p className="heading">Create User</p>
          </div>
        </Grid>
      </Grid> */}
      <Grid
        container
        className="d-flex justify-content-center"
        sx={{ paddingTop: "2rem" }}
      >
        <Box sx={{ width: "95%" }}>
          <Stepper
            activeStep={activeStep}
            sx={{ padding: "0 12rem 1rem 12rem" }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {
            <React.Fragment>
              <Grid
                container
                className="overCard"
                sx={{ height: "30rem", overflow: "scroll" }}
              >
                {activeStep === 0 ? (
                  <OrgDetails
                    selectedOrg={selectedOrg}
                    setSelectedOrg={setSelectedOrg}
                  />
                ) : (
                  <EditDetaisl
                    selectedOrg={selectedOrg}
                    data={data}
                    setData={setData}
                  />
                )}
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />

                {activeStep === steps.length - 1 ? (
                  <Button onClick={updateDetails}>Update</Button>
                ) : (
                  <Button onClick={handleNext}>Next</Button>
                )}
              </Box>
            </React.Fragment>
          }
        </Box>
      </Grid>
    </>
  );
}
