import React, { useContext, useEffect, useState } from "react";
import ShipmentListTable from "./ShipmentListTable";
import "../shipment.css";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { basename } from "../../../Config";
import Searchbar from "../../../components/searchbar/Searchbar";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import urlResolver from "../../../utils/urlResolver";
import { IconButton, Skeleton } from "@mui/material";
import { AuthContext } from "../../../store/context/AuthProvider";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
const Tableindex = ({
  sortColumnCopy,
  setSearch,
  search,
  searchedClicked,
  setSearchedClicked,
  setCurrentPage,
  currentPage,
  getShipmentCollection,
  setLoading,
  loading,
  setSortmodal,
  totalCount,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  setStatus,
  filter,
  setFilter,
  filterObj,
  loadingOnSearch,
  setLoadingOnSearch,
  crossButtonClick,
  inputValidation,
  searchValidation,
  setSearchValidation,
}) => {
  const context = useContext(AuthContext);
  const [loadingTable, setLoadingTable] = useState(false);
  const [tableCols, setTableCols] = useState([]);
  const getTableCols = () => {
    setLoadingTable(true);
    instance
      .get(API_PATH.GET_SHIPMENT_TABLE_COLS)
      .then((res) => {
        if (res.data.length > 0) {
          let sortedCols = res.data;
          sortedCols = res.data.sort((a, b) => a.Order - b.Order);
          setTableCols(sortedCols);
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoadingTable(false);
      });
  };
  const updateTableCols = (updatedCols) => {
    setLoadingTable(true);
    let cols = updatedCols;

    updatedCols.map((item, index) => {
      cols[index].Order = index + 1;
    });

    instance
      .post(API_PATH.POST_SHIPMENT_TABLE_COLS, cols)
      .then((res) => {
        getTableCols();
      })
      .catch((err) => {
        setLoadingTable(false);
      })
      .finally(() => {
        // setLoadingTable(false);
      });
  };
  useEffect(() => {
    getTableCols();
  }, []);
  function isObject(value) {
    return typeof value === "object" && value !== null && !Array.isArray(value);
  }

  return (
    <div className="container-fluid">
      <div className="d-flex mb-3 justify-content-between">
        <div className="col-md-11 col-sm-10">
          <div className="headersearch searchShipmentDiv ">
            <input
              type="text"
              className="form-control "
              placeholder="Search Shipment no/ HBL no/ PO no/ Container no"
              searchValidation
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyDown={(e) => {
                const validation = inputValidation(search);
                setSearchValidation(validation);
                if (e.key === "Enter" && validation) {
                  setSearchedClicked(true);
                  getShipmentCollection(0, "");
                  setLoadingOnSearch(true);
                }
              }}
            />
            <img
              src={urlResolver("assets/search_icon.svg")}
              onClick={(e) => {
                const validation = inputValidation(search);
                setSearchValidation(validation);
                if (search && validation) {
                  setSearchedClicked(true);
                  getShipmentCollection(0, "", e.target.value);
                  setLoadingOnSearch(true);
                }
              }}
              style={{ color: "rgba(var(--text-heading-dark))" }}
              className="searchIcon"
            />
            {search && (
              <CloseOutlinedIcon
                style={{
                  position: "absolute",
                  right: "1rem",
                  cursor: "pointer",
                }}
                className="searchClearIcon"
                onClick={() => {
                  setSearchValidation(true);
                  setSearch("");
                  setSearchedClicked(true);
                  crossButtonClick.current = true;
                  getShipmentCollection(0, "");
                  setLoadingOnSearch(true);
                }}
              />
            )}
          </div>
          {inputValidation(search) ? (
            ""
          ) : (
            <div
              className="validation-error"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Special characters are not allowed.
            </div>
          )}
        </div>

        <div>
          <div
            className={`d-flex justify-content-center align-items-center filter-div ${
              Object.values(filterObj.current).filter((element) => {
                if (element?.length > 0 || isObject(element)) return 1;
              }).length > 0
                ? "color-style"
                : ""
            }`}
          >
            <div
              className=" d-flex cursorPointer "
              onClick={() => {
                setFilter({
                  TransportMode: filter.TransportMode,
                  ContainerType: filter.ContainerType,
                  Origin: filter.Origin,
                  Destination: filter.Destination,
                  Carrier: filter.Carrier,
                  Consignor: filter.Consignor,
                  Consignee: filter.Consignee,
                  OrderNumber: filter.OrderNumber,
                  MasterBill: filter.MasterBill,
                  FromETA: filter.FromETA,
                  ToETA: filter.ToETA,
                  ToETD: filter.ToETD,
                  FromETD: filter.FromETD,
                  FromATA: filter.FromATA,
                  ToATA: filter.ToATA,
                  FromATD: filter.FromATD,
                  ToATD: filter.ToATD,
                });
                setSortmodal(true);
              }}
            >
              {/* <img src={urlResolver("assets/cone.svg")} />
               */}
              <FilterAltOutlinedIcon
                onClick={() => setSortmodal(true)}
                color={`${
                  Object.values(filterObj.current).filter((element) => {
                    if (element?.length > 0 || isObject(element)) return 1;
                  }).length > 0
                    ? "third"
                    : "primary"
                }`}
              />
            </div>
            <div className=" d-flex cursorPointer ">
              {Object.values(filterObj.current).filter((element) => {
                if (element?.length > 0 || isObject(element)) return 1;
              }).length > 0 && (
                <div className="d-flex ps-2">
                  <div style={{ border: "1px solid white" }} />
                  <IconButton disabled={loadingOnSearch} size="small">
                    <CloseOutlinedIcon
                      color="third"
                      onClick={() => {
                        setLoadingOnSearch(true);
                        filterObj.current = {
                          TransportMode: "",
                          ContainerType: "",
                          Origin: "",
                          Destination: "",
                          Carrier: "",
                          Shipper: "",
                          Consignor: "",
                          Consignee: "",
                          OrderNumber: "",
                          MasterBill: "",
                          FromETA: null,
                          ToETA: null,
                          ToETD: null,
                          FromETD: null,
                          FromATA: null,
                          ToATA: null,
                          FromATD: null,
                          ToATD: null,
                        };
                        setFilter({
                          TransportMode: "",
                          ContainerType: "",
                          Origin: "",
                          Destination: "",
                          Carrier: "",
                          Shipper: "",
                          Consignor: "",
                          Consignee: "",
                          OrderNumber: "",
                          MasterBill: "",
                          FromETA: null,
                          ToETA: null,
                          ToETD: null,
                          FromETD: null,
                          FromATA: null,
                          ToATA: null,
                          FromATD: null,
                          ToATD: null,
                        });
                        getShipmentCollection(0, ""); //api
                      }}
                    />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row px-3">
        {loadingTable ? (
          <div className="skeleton-div">
            <Skeleton style={{ height: "47rem" }} />
          </div>
        ) : (
          <ShipmentListTable
            updateTableCols={updateTableCols}
            setTableCols={setTableCols}
            tableCols={tableCols}
            sortColumnCopy={sortColumnCopy}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            getShipmentCollection={getShipmentCollection}
            setLoading={setLoading}
            loading={loading}
            totalCount={totalCount}
            sortColumn={sortColumn}
            setSortColumn={setSortColumn}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            setStatus={setStatus}
            loadingOnSearch={loadingOnSearch}
            setLoadingOnSearch={setLoadingOnSearch}
          />
        )}
      </div>
    </div>
  );
};

export default Tableindex;
