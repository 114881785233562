const Information = {
    Dashboard: {
        "Upcoming_Shipments": "This section lists all shipments arriving at the destination port within the next 30 days.",

        "Finances": "FINNCES",

        "Favorites": "This section displays shipments marked as favorites using the star option.",

        "ETD_Exception": "Shipments that have not met their Estimated Time of Departure (ETD) date will be shown in this section.",

        "ETA_Exception": "Shipments that have not met their Estimated Time of Arrival (ETA) date will be shown in this section.",

        "Outstanding": "This section displays the sum of outstanding amounts.",

        "Due_Amount": "This section shows the sum of amounts that are due."
    },
    User_Logs: {
        "resetPassword": ""
    },
    Shipment_Analytics: {
        "Shipment_Analysis": "",

        "Mode_wise": "This graph categorizes shipments based on container mode. ",

        "Delivery_Status": "This chart analyzes shipments based on ETA vs. ATA at discharge port.",

        "Collected_PrePaid": "In this chart, shipments are categorized based on payment terms. ",

        "Tonnage": "This chart allows analysis of shipments by TEU, volume, and chargeable weight based on container type.",

        "Shipment_Sector_wise": "This map displays the count of shipments by country based on direction."

    },
    Carrier_Analysis: {
        "Carrier_Analysis": "",

        "Mode_wise": "This graph enables the comparison of multiple carriers based on the number of shipments they've handled.",

        "Tonnage": "This chart provides an analysis of multiple carriers' shipments based on TEU, volume, and chargeable weight.",

        "Transit_time": "In this graph, you can compare the average transit time of multiple carriers for given origin and destination.",

    },
    KPI_Analysis: {
        "KPI_Analysis": "",

        "HBLIssue": "This chart shows the number of shipments that fall above or below the set threshold value in terms of days between HBL issue and shipped on.",

        "Departure": "This chart shows the number of shipments that fall above or below the set threshold value in terms of days between cargo departure from the origin port and actual pick-up.",

        "Actual_Delivery": "This chart shows the number of shipments that fall above or below the set threshold value in terms of days between actual delivery and delivery at the destination port.",

        "Set_threshold_Value": "Use this feature to set threshold values for the charts below. "
    },
    Shipment_Listing: {
        "Milestones": "",
        "Container_Milestone": "",
        "Routing": "",
        "Export to excel": "Use this feature to export all the data displayed on the user interface in Excel format.",
        "TabInformation": {
            "ALL": "All shipments are displayed here.",

            "Pre-loaded": "Shipments that have not yet departed from the Port of Loading are displayed here.",

            "In-transit": "Shipments that have departed from the Port of Loading are displayed here.",

            "Arrived": "Shipments that have arrived at the Last Discharge port are displayed here.",
            "Archive": `Shipments that have completed their journey are displayed here.`,
            "ArchiveSubInfo": {
                "AboutAir": "90 days after arrival for Air shipments. ",
                "Other": " 90 days after the empty container returned to port for Maritime shipments."
            }

        },

        "Customize_columns": "Use this feature to select and save your preferred column display preferences on the user interface.",

        "Uploading_document": "Documents uploaded here will be displayed in the shipment’s eDoc section in Cargowise. "

    },
    Statement_Of_Account: {
        "Aged_Outstanding": "This section displays the sum of outstanding amounts in specific currency ranges within a time frame of 0-90 days",

        "Aging": " This column displays the total number of days that have passed since the invoice was raised.",

        "Export_to_excel": "Use this feature to export all the data displayed on the user interface in Excel format.",

        "Invoice_Amount": "This section shows the actual amount, including tax, for which the transaction was raised.",

        "Outstanding_Amount": "This section displays the remaining amount to be paid against a transaction.",
    }

}
export default Information
