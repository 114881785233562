export const TableColumns = [
  {
    ColumnId: 1,
    ColumnName: "Shipment no.",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ShipmentId",
  },
  {
    ColumnId: 2,
    ColumnName: "Volume",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "TotalVolume",
  },
  {
    ColumnId: 3,
    ColumnName: "Weight",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "TotalWeight",
    AltKey: "TotalWeightUQ",
  },
  {
    ColumnId: 4,
    ColumnName: "Transport no.",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "TransportMode",
  },
  {
    ColumnId: 5,
    ColumnName: "Container Type",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ContainerMode",
  },
  {
    ColumnId: 6,
    ColumnName: "Container No.",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ContainerNumbers",
  },

  {
    ColumnId: 7,
    ColumnName: "PO/Order Ref No",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "OrderRef",
  },
  // {
  //   ColumnId: 8,
  //   ColumnName: "Master Bill No.",
  //   IsVisible: false,
  //   UserRank: 1,
  //   DefaultRank: 2,
  //   IsSelected: false,
  //   DataKey: "MasterBillNumber",
  //   AltKey: 0,
  // },
  {
    ColumnId: 9,
    ColumnName: "	House Bill No.",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "HouseBillNumber",
    AltKey: 0,
  },
  {
    ColumnId: 10,
    ColumnName: "Consignor",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ConsignorName",
    AltKey: 0,
  },
  {
    ColumnId: 11,
    ColumnName: "Consignee ",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ConsigneeName",
    AltKey: 0,
  },
  {
    ColumnId: 12,
    ColumnName: "Origin Port ",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "OriginPortName",
    AltKey: 0,
  },
  {
    ColumnId: 13,
    ColumnName: "Destination Port ",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "DestinationPortName",
    AltKey: 0,
  },
  {
    ColumnId: 14,
    ColumnName: "ETA ",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ETADate",
    AltKey: 0,
  },
  {
    ColumnId: 15,
    ColumnName: "ETD ",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ETDDate",
    AltKey: 0,
  },
  {
    ColumnId: 16,
    ColumnName: "INCO Terms ",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "INCOTerm",
    AltKey: 0,
  },
  {
    ColumnId: 17,
    ColumnName: "Release Terms ",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ReleaseType",
    AltKey: 0,
  },
  {
    ColumnId: 18,
    ColumnName: "Carrier ",
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "CarrierName",
    AltKey: 0,
  },
  {
    ColumnId: 19,
    ColumnName: "Status ",
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: "ShipmentStatusDescription",
    AltKey: 0,
  },
];
