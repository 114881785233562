import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { baseOrderUrl, customerName, LOCAL_STORAGE_KEY } from "../../Config";
import "./Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import SettingsIcon from "@mui/icons-material/Settings";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../store/context/AuthProvider";
import ChangePasswordModal from "../model/ChangePasswordModal";
import DocUploadShipmentSidebar from "../sidebar/DocUploadShipmentSidebar";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import HomeIcon from "@mui/icons-material/Home";
import { useState } from "react";
import Notification from "../../screens/notifications/Index";
import urlResolver from "../../utils/urlResolver";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { resetStepper } from "../../redux/orderRedux/createOrder/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import orderInstance from "../../api/orderInterceptor";
import { API_PATH } from "../../constant/apiPath";
import { updatePermissionData } from "../../redux/orderRedux/orderPermission";
import SummarizeIcon from "@mui/icons-material/Summarize";
import getpermision from "../../screens/order/Permission/permison";
import { async } from "q";
import { updateOrderSpecific } from "../../redux/orderRedux/createOrder/orderspecificslice";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { updateObj } from "../../redux/orderRedux/createOrder/linedetailsSlice";
import { updatetabData } from "../../redux/orderRedux/createOrder/orderplanSlice";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Change Password", "Logout"];

const Header = () => {
  const location = useLocation();
  const context = React.useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  const { orderDetailsFromAPI } = useSelector((state) => state.order);
  const emptyData = () => {
    // console.log("Handle data fill called.");

    dispatch(
      updateOrderSpecific({
        objName: "orderNumber",
        name: "orderNumber",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderNumber",
        name: "cw1OrderNumber",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderNumber",
        name: "orderDate",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderNumber",
        name: "sendingAgent",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderNumber",
        name: "receivingAgent",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "supplier",
        name: "supplierName",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "supplier",
        name: "supplierAddress",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "buyer",
        name: "buyerName",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "buyer",
        name: "buyerAddress",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "buyer",
        name: "finalDeliveryLocation",
        value: "",
      })
    );

    dispatch(
      updateOrderSpecific({
        objName: "orderDetailsFromAPI",
        name: "data",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "currencyOfPayment",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "weight",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "volume",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "containerMode",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "reqWork",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "serviceLevel",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "invoiceNumber",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "incoTerm",
        value: {},
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "transportMode",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "reqStore",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "shipmentNumber",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "originCountry",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "goodsDescription",
        value: "",
      })
    );
    dispatch(
      updateOrderSpecific({
        objName: "orderDetails",
        name: "specialInstructions",
        value: "",
      })
    );
    dispatch(
      updateObj({
        value: {
          lineNo: 0,
          itemNo: "",
          description: "",
          innerPacks: "",
          innerPackageType: "",
          outerPack: "",
          outerPackageType: "",
          outerPackLength: "",
          outerPackWidth: "",
          outerPackHeight: "",
          unitOfDimenssions: "",
          qtyOrdered: "",
          qtyReceived: "",
          qtyRemaining: "",
          unitOfQty: "",
          actualVolume: "",
          unitOfVolume: "",
          actualWeight: "",
          unitOfWeight: "",
          itemPrice: "",
          totalPrice: "",
          reqInStoreDate: "",
          incoTerm: "",
          additionalTerms: "",
          confirmNumber: "",
          containerNumber: "",
          confirmDate: "",
          deleteIndex: 0,
        },
      })
    );
    dispatch(
      updatetabData({
        value: {
          index: 0,
          originCountryCode: "",
          originPort: "",
          destinationCountryCode: "",
          desnationPort: "",
          weeks: 1,
          departureDate: "",
          cutOffDate: "",
          arrivalDate: "",
          direction: "",
          vessel: "",
          voyage: "",
        },
      })
    );
  };

  // console.log(
  //   location.pathname.split("/").at(-1) === "listing" ||
  //     location.pathname.split("/").at(-1) === "analytics",
  //   "locationlocation"
  // );
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    emptyData();
    // console.log("orderDetailsFromAPI", orderDetailsFromAPI);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    context.setGridViewShipment(true); //to ensure that we land on the card page when click on shipment management...(MENU)
    setAnchorElNav(null);
    dispatch(resetStepper());
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const locationDetialsLastposition = location.pathname.split("/").at(-1);
  const displayData = [
    {
      module: "shipment",
      children: [
        {
          displayName: "Shipment Listing",
          route: Routeconstant.SHIPMENT_LISTING,
        },
        {
          displayName: "Shipment Analytics",
          route: Routeconstant.SHIPMENT_ANALYTICS,
        },
      ],
    },
    {
      module: "newshipment",
      children: [
        {
          displayName: "",
        },
      ],
    },
    {
      module: "orderManagement",
      children: [
        {
          displayName: "Dashboard",
          route: Routeconstant.ORDER_DASHBOARD,
          icon: <DashboardIcon color="primary" />,
          displayPermission: true,
        },
        {
          displayName: "Create Order",
          route: Routeconstant.CREATE_ORDER,
          icon: <AddCircleIcon color="primary" />,
          displayPermission: permisisonOrder?.CreateOrder ?? true,
        },
        {
          displayName: "Reports",
          route: Routeconstant.ORDER_REPORTS,
          icon: <SummarizeIcon color="primary" />,
          displayPermission: true,
        },
        {
          displayName: "User Log",
          route: "/order/system-log/all",
          icon: <WysiwygIcon color="primary" />,
          displayPermission: true,
        },
        {
          displayName: "Admin Settings",
          route: Routeconstant.ADMIN_SETTINGS,
          icon: <SupervisorAccountIcon color="primary" />,
          displayPermission: true,
        },
      ],
    },
  ];

  return (
    <>
      {modalOpen ? (
        <ChangePasswordModal
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
        />
      ) : null}
      {notificationOpen ? (
        <Notification
          notificationOpen={notificationOpen}
          setNotificationOpen={setNotificationOpen}
        />
      ) : null}
      <AppBar position="static" color="transparent">
        {/* <Container> */}
        <Toolbar disableGutters>
          <div className="header-logo">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <img src={urlResolver("assets/header_icon.svg")} />
            </IconButton>

            {/* menu item for larger screen */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Link
                to={Routeconstant.LANDING_PAGES}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "#d0eeff",
                      }}
                    >
                      <HomeIcon color="primary" />
                    </IconButton>

                    <p
                      className="header-text"
                      style={{
                        marginLeft: "12%",
                      }}
                    >
                      Dashboard
                    </p>
                  </Typography>
                </MenuItem>
              </Link>
              <Link
                to={Routeconstant.SHIPMENT_LISTING}
                className="links-react-router"
              >
                <MenuItem
                  onClick={() => {
                    handleCloseNavMenu();
                  }}
                >
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/ShipmentManagementIcon.svg")}
                    />
                    <p className="header-text">Shipment Management</p>
                  </Typography>
                </MenuItem>
              </Link>
{/* 
              <Accordion
                className="accordian-items"
                style={{ boxShadow: "none" }}
                onClick={async () => {
                  if (Object.keys(permisisonOrder).length === 0) {
                    const data = await getpermision();
                    dispatch(updatePermissionData({ ...data }));
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="handle-dash"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      const data = await getpermision();
                      dispatch(updatePermissionData({ ...data }));
                    }}
                  >
                    <img
                      alt=""
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderManagement.svg")}
                    />{" "}
                    <p className="header-text">Order Management</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {displayData.map((item, ind) => {
                    return (
                      <>
                        {item.module === "orderManagement" && (
                          <>
                            {item.children.map((i, index) => {
                              if (i.displayPermission) {
                                return (
                                  <Link
                                    to={i.route}
                                    className="links-react-router"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                      }}
                                      onClick={() => {
                                        handleCloseNavMenu();
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          backgroundColor: "#d0eeff",
                                        }}
                                      >
                                        {i.icon}
                                      </IconButton>
                                      <p
                                        style={{
                                          paddingLeft: "1rem",
                                        }}
                                        className="header-text"
                                      >
                                        {i.displayName}
                                      </p>
                                    </div>
                                  </Link>
                                );
                              } else {
                              }
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion> */}

              {/* <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  to={Routeconstant.ORDER_LISTING}
                  className="links-react-router"
                >
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderManagement.svg")}
                    />
                    <p className="header-text">Order Management</p>
                  </Typography>
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  // to={Routeconstant.ENQUIRY}
                  className="links-react-router"
                >
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderShipmentInquiry.svg")}
                    />
                    <p className="header-text">Order / Shipment Enquiry</p>
                  </Typography>
                </Link>
              </MenuItem> */}
              {((customerName === "SAR Transport" &&
                context.role === "Sales") ||
                customerName === "SFLInsight") && (
                <Link to={Routeconstant.RMS} className="links-react-router">
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      className="d-flex align-items-center"
                    >
                      <img
                        className="icon-menuitem"
                        src={urlResolver("assets/StatementIcon.svg")}
                      />
                      <p className="header-text">RMS</p>
                    </Typography>
                  </MenuItem>
                </Link>
              )}

              <Link to={Routeconstant.STATEMENT} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/StatementIcon.svg")}
                    />
                    <p className="header-text">Statement of Account</p>
                  </Typography>
                </MenuItem>
              </Link>
              {context?.userData?.Role?.includes("Admin") && (
                <Link
                  to={Routeconstant.USER_LOGS}
                  className="links-react-router"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography
                      textAlign="center"
                      className="d-flex align-items-center"
                    >
                      <img
                        className="icon-menuitem"
                        src={urlResolver("assets/UserLogsIcon.svg")}
                      />
                      <p className="header-text">Admin Portal</p>
                    </Typography>
                  </MenuItem>
                </Link>
              )}
              {/* <Link
                to={Routeconstant.ADMIN_SETTINGS}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">Admin Settings</p>
                  </Typography>
                </MenuItem>
              </Link> */}
              {/* <Link
                to={Routeconstant.ENQUIRYDASHBOARD}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">General Enquiry</p>
                  </Typography>
                </MenuItem>
              </Link> */}
            </Menu>

            {/* menu item for smaller screen */}
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <Link
                to={Routeconstant.LANDING_PAGES}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <IconButton
                      sx={{
                        backgroundColor: "#d0eeff",
                      }}
                    >
                      <HomeIcon color="primary" />
                    </IconButton>

                    <p
                      className="header-text"
                      style={{
                        marginLeft: "12%",
                      }}
                    >
                      Dashboard
                    </p>
                  </Typography>
                </MenuItem>
              </Link>
              <Accordion className="accordian-items">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/ShipmentManagementIcon.svg")}
                    />
                    <p className="header-text">Shipment Management</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {displayData.map((item, ind) => {
                    return (
                      <>
                        {item.module === "shipment" && (
                          <ul key={ind}>
                            {item.children.map((i, index) => {
                              return (
                                <Link
                                  key={index}
                                  to={i.route}
                                  style={{ textAlign: "left" }}
                                  onClick={() => {
                                    dispatch(
                                      updateOrderSpecific({
                                        objName: "orderDetailsFromAPI",
                                        name: "data",
                                        value: {},
                                      })
                                    );
                                  }}
                                >
                                  <li
                                    onClick={handleCloseNavMenu}
                                    className="header-text"
                                  >
                                    {i.displayName}
                                  </li>
                                </Link>
                              );
                            })}
                          </ul>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
              {/* <Accordion
                className="accordian-items"
                style={{ boxShadow: "none" }}
                onClick={async () => {
                  if (Object.keys(permisisonOrder).length === 0) {
                    const data = await getpermision();
                    dispatch(updatePermissionData({ ...data }));
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className="handle-dash"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={async () => {
                      const data = await getpermision();
                      dispatch(updatePermissionData({ ...data }));
                    }}
                  >
                    <img
                      alt=""
                      className="icon-menuitem"
                      src={urlResolver("assets/OrderManagement.svg")}
                    />{" "}
                    <p className="header-text">Order Management</p>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {displayData.map((item, ind) => {
                    return (
                      <>
                        {item.module === "orderManagement" && (
                          <>
                            {item.children.map((i, index) => {
                              if (i.displayPermission) {
                                return (
                                  <Link
                                    to={i.route}
                                    className="links-react-router"
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "5px",
                                      }}
                                      onClick={() => {
                                        handleCloseNavMenu();
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          backgroundColor: "#d0eeff",
                                        }}
                                      >
                                        {i.icon}
                                      </IconButton>
                                      <p
                                        style={{
                                          paddingLeft: "1rem",
                                        }}
                                        className="header-text"
                                      >
                                        {i.displayName}
                                      </p>
                                    </div>
                                  </Link>
                                );
                              } else {
                              }
                            })}
                          </>
                        )}
                      </>
                    );
                  })}
                </AccordionDetails>
              </Accordion> */}

              {((customerName === "SAR Transport" &&
                context.role === "Sales") ||
                customerName === "SFLInsight") && (
                <Link to={Routeconstant.RMS} className="links-react-router">
                  <div className="d-flex mobile-menu align-items-center">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/StatementIcon.svg")}
                    />
                    <p className="header-text">RMS</p>
                  </div>
                </Link>
              )}
              <Link to={Routeconstant.STATEMENT} className="links-react-router">
                <MenuItem onClick={handleCloseNavMenu}>
                  {/* <div className="d-flex mobile-menu"> */}
                  <img
                    className="icon-menuitem"
                    src={urlResolver("assets/StatementIcon.svg")}
                  />
                  <p className="header-text">Statement of Account</p>
                  {/* </div> */}
                </MenuItem>
              </Link>
              {/* <Link
                to={Routeconstant.ENQUIRYDASHBOARD}
                className="links-react-router"
              >
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography
                    textAlign="center"
                    className="d-flex align-items-center"
                  >
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">General Enquiry</p>
                  </Typography>
                </MenuItem>
              </Link> */}
              {context?.userData?.Role?.includes("Admin") && (
                <Link
                  to={Routeconstant.USER_LOGS}
                  className="links-react-router"
                >
                  <div className="d-flex mobile-menu align-items-center">
                    <img
                      className="icon-menuitem"
                      src={urlResolver("assets/UserLogsIcon.svg")}
                    />
                    <p className="header-text">Admin Portal</p>
                  </div>
                </Link>
              )}
            </Menu>

            <a href={`https://www.broekmanlogistics.com/`} target="_blank">
              <IconButton
                onClick={() => {
                  // navigate("https://softfreightlogic.com/");
                }}
              >
                <img src={urlResolver(`assets/headerLogo.png`)} style={{height : "2rem"}} />
              </IconButton>
            </a>
            {/* </Typography> */}
          </div>

          {/* sub menu item only for larger screen */}
          {(["listing", "analytics"].includes(locationDetialsLastposition) ||
            context.headerTab) && (
            <Typography sx={{ display: { xs: "none", md: "flex" } }}>
              <ul className="navbar-nav d-flex flex-row">
                {displayData.map((item, index) => {
                  if (location.pathname.includes(item.module)) {
                    return (
                      <>
                        {item.children.map((i, ind) => {
                          return (
                            <li className="nav-item mr-5" key={ind}>
                              {i.displayName === "Order Analytics" ? (
                                <Link
                                  // to={i.route}
                                  className="links-react-router "
                                  onClick={() => {
                                    dispatch(
                                      updateOrderSpecific({
                                        objName: "orderDetailsFromAPI",
                                        name: "data",
                                        value: {},
                                      })
                                    );
                                  }}
                                >
                                  <div
                                    className={
                                      location.pathname.includes(i.route)
                                        ? "pt-0 pb-0 dark-color"
                                        : "pt-0 pb-0 header-sub-text"
                                    }
                                  >
                                    <div
                                      className={
                                        location.pathname.includes(i.route)
                                          ? "header-border"
                                          : ""
                                      }
                                      style={{ margin: "0" }}
                                    ></div>
                                    {i.displayName}
                                  </div>
                                </Link>
                              ) : (
                                <Link
                                  to={i.route}
                                  className="links-react-router "
                                >
                                  <div
                                    className={
                                      location.pathname.includes(i.route)
                                        ? "pt-0 pb-0 dark-color"
                                        : "pt-0 pb-0 header-sub-text"
                                    }
                                  >
                                    {i.displayName}
                                    <div
                                      className={
                                        location.pathname.includes(i.route)
                                          ? "header-border"
                                          : ""
                                      }
                                      style={{ margin: "0" }}
                                    />
                                  </div>
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </>
                    );
                  }
                })}
              </ul>
            </Typography>
          )}

          {/* profile icon for larger screen */}
          <Typography sx={{ display: { xs: "none", md: "flex" } }}>
            <div className="d-flex align-items-center">
              <Box
                sx={{ flexGrow: 0 }}
                className="profile-icon d-flex justify-content-center align-items-center"
              >
                {/* <Tooltip title="Open settings"> */}
                {context.userData.Name && (
                  <IconButton sx={{ p: 0, border: "none" }}>
                    <Avatar
                      alt="Remy Sharp"
                      src={`https://ui-avatars.com/api/?name=${context.userData.Name.split("")[0]
                        }`}
                      style={{ height: "35px", width: "35px" }}
                    />
                  </IconButton>
                )}
                {/* </Tooltip> */}
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        className="font-change"
                        onClick={() => {
                          if (setting === "Change Password") {
                            setModalOpen(true);
                          } else if (setting === "Logout") {
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                            localStorage.clear();
                            // window.location.reload();
                            context.setToken({});
                            context.setUserData({});
                            context.setIslogin(false);
                            navigate(Routeconstant.LOGIN);
                          }
                        }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
                <div className="d-flex">
                  <p
                    style={{
                      margin: 0,
                      borderRight: "1px solid #D0DEF0",
                      padding: "0 1rem",
                      color: "var(--color-primary)",
                    }}
                  >
                    {context?.userData?.Name ?? ""}
                  </p>
                  <div className="settings-icon">
                    <SettingsIcon
                      onClick={handleOpenUserMenu}
                      style={{
                        color: "#D0DEF0",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              </Box>

              {/* <div className="px-3" onClick={notificationDrawer}>
                {" "}
                <NotificationsNoneIcon className="header-notification" />
              </div> */}
            </div>
          </Typography>

          {/* profile icon for smaller screen */}
          <Typography sx={{ display: { xs: "flex", md: "none" } }}>
            {/* <div className="px-3" onClick={notificationDrawer}>
              <NotificationsNoneIcon
                style={{
                  fontSize: "3rem",
                  color: "var(--color-primary)",
                  cursor: "pointer",
                }}
              />
            </div> */}

            <div className="d-flex align-items-center">
              <Box
                sx={{ flexGrow: 0 }}
                className="profile-icon d-flex justify-content-center align-items-center"
              >
                {context.userData.Name && (
                  <IconButton sx={{ p: 0 }}>
                    <IconButton sx={{ p: 0, border: "none" }}>
                      <Avatar
                        onClick={handleOpenUserMenu}
                        alt="Remy Sharp"
                        src={`https://ui-avatars.com/api/?name=${context.userData.Name.split("")[0]
                          }`}
                        style={{ height: "35px", width: "35px" }}
                      />
                    </IconButton>
                  </IconButton>
                )}

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={(event) => {
                        const selectedItem =
                          event.target.querySelector("#setting").innerHTML;
                        if (selectedItem === "Change Password") {
                          setModalOpen(true);
                        } else if (selectedItem === "Logout") {
                          localStorage.removeItem(LOCAL_STORAGE_KEY);
                          localStorage.clear();
                          context.setToken({});
                          context.setUserData({});
                          context.setIslogin(false);
                          navigate(Routeconstant.LOGIN);
                        }
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography
                        id={"setting"}
                        textAlign="center"
                        className="font-change"
                        onClick={() => {
                          if (setting === "Change Password") {
                            setModalOpen(true);
                          } else if (setting === "Logout") {
                            localStorage.removeItem(LOCAL_STORAGE_KEY);
                            localStorage.clear();
                            context.setToken({});
                            context.setUserData({});
                            context.setIslogin(false);
                            navigate(Routeconstant.LOGIN);
                          }
                        }}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </div>
          </Typography>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
      {/* <DocUploadShipmentSidebar /> */}
    </>
  );
};
export default Header;
