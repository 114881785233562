import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { basename } from "../../../Config";
import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../shipment.css";
import ShipmentAnalysis from "./analysis/ShipmentAnalysis";
import CarrierAnalysis from "./analysis/CarrierAnalysis";
import KPIAnalysis from "./analysis/KPIAnalysis";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect } from "react";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";

import Modefilter from "./analysis/filter/Modefilter";
import YearFilter from "./analysis/filter/YearFilter";
import CarrierCodesFilter from "./analysis/filter/CarrierCodesFilter";
import { AuthContext } from "../../../store/context/AuthProvider";
import accessGiven from "../../../utils/permission";
const ShipmentAnalytics = () => {
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState("md");
  const [maxWidth, setMaxWidth] = useState("md");
  const [title, setTitle] = useState("");
  const [component, setComponent] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [modeList, setModeList] = useState([]);
  const [modeListCopy, setModeListCopy] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [yearListCopy, setYearListCopy] = useState([]);
  const [carrierList, setCarrierList] = useState([]);
  const [carrierListCopy, setCarrierListCopy] = useState([]);
  const d = new Date();
  const [filterOptions, setFilterOptions] = useState({
    filterMode: "SEA",
    filterYear: d.getFullYear(),
    filterCarrier: null,
  });

  const context = useContext(AuthContext);

  const handleClickOpen = (title, comp) => {
    setTitle(title);
    setComponent(comp);
    setOpen(true);
  };
  let access = accessGiven();
  const getAllCountry = () => {
    instance
      .get(API_PATH.GET_LISTOF_COUNTRY)
      .then((res) => {
        setCountryList(
          res.data.sort((a, b) =>
            a.Description > b.Description
              ? 1
              : b.Description > a.Description
              ? -1
              : 0
          )
        );
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const getModeList = () => {
    instance
      .get(API_PATH.GET_TRANSPORT_MODES)
      .then((res) => {
        setModeList(res.data);
        setModeListCopy(res.data);
        getYearsList();
        // setMappingData(res?.data[0] ?? "");
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const getYearsList = () => {
    instance
      .get(API_PATH.GET_YEARS_LIST)
      .then((res) => {
        setYearList(res.data);
        setYearListCopy(res.data);
        getCarrierCode();
        // setMappingData(res?.data[0] ?? "");
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const getCarrierCode = (modeSelected) => {
    instance
      .get(`${API_PATH.GET_CARRIER_CODES}?Mode=${modeSelected ?? "SEA"}`)
      .then((res) => {
        context.setFilterLoaded(true);
        setCarrierList(res.data);
        setCarrierListCopy(res.data);
        setFilterOptions({
          ...filterOptions,
          filterCarrier: "",
        });
        // setMappingData(res?.data[0] ?? "");
      })
      .catch((err) => {
        context.setFilterLoaded(true);
        console.log(err, "err");
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  useEffect(() => {
    getAllCountry();
    getModeList();
  }, []);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          maxWidth: "100%",
        }}
      >
        {/* FILTER OPTIONS */}
        <Grid item lg={2.5} xl={2.5} sm={12} md={12} xs={12}>
          <div
            // className="col-lg-3 col-sm-12 col-md-4 "
            style={{
              backgroundColor: "var(--color-white)",
              borderRadius: "5px",
            }}
          >
            <p className="subtitle-text">Filter Options</p>
            <Divider
              style={{
                marginBottom: "10px",
              }}
            />
            {/* Year */}
            <YearFilter
              yearList={yearList}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              yearListCopy={yearListCopy}
              setYearListCopy={setYearListCopy}
              setYearList={setYearList}
            />
            {/* Mode */}
            <Modefilter
              modeList={modeList}
              setModeList={setModeList}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              modeListCopy={modeListCopy}
              setModeListCopy={setModeListCopy}
              getCarrierCode={getCarrierCode}
            />

            {/* ETD/ETA */}
            {/* Carrier */}
            <CarrierCodesFilter
              carrierList={carrierList}
              setCarrierList={setCarrierList}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              setCarrierListCopy={setCarrierListCopy}
              carrierListCopy={carrierListCopy}
            />
          </div>
        </Grid>
        {/* CHARTS */}
        <Grid item lg={9.5} xl={9.5} sm={12} xs={12} md={12}>
          <Grid container spacing={2}>
            {/* SHIPMENT ANALYSIS */}
            <Grid item lg={12}>
              <ShipmentAnalysis
                handleClickOpen={handleClickOpen}
                filterOptions={filterOptions}
              />
            </Grid>
            {/* CARRIER ANALYSIS */}
            {access.CarrierAnalysis && (
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <CarrierAnalysis
                  countryList={countryList}
                  filterOptions={filterOptions}
                  handleClickOpen={handleClickOpen}
                />
              </Grid>
            )}
            {access.KPIAnalysis && (
              <Grid item lg={12} sm={12} xs={12} md={12}>
                <KPIAnalysis
                  filterOptions={filterOptions}
                  handleClickOpen={handleClickOpen}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          sx={{
            minHeight: "20vh",
            padding: "3%",
            height: "100%",
          }}
        >
          {/* <DialogContentText></DialogContentText> */}

          {component}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};

export default ShipmentAnalytics;
