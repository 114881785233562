import { useEffect, useState } from "react";
import { styled, Button } from "@mui/material";
import React from "react";
import { basename } from "../../Config";
import { AuthContext } from "../../store/context/AuthProvider";
import urlResolver from "../../utils/urlResolver";
import { useLocation } from "react-router-dom";
import { LocationOff } from "@mui/icons-material";
const RmsButton = styled(Button)(({ theme }) => ({
  // Custom styles for the button
}));

const CreateBtnMargin = (props) => {
  const context = React.useContext(AuthContext);
  const transportType = props.transportType;
  const [activeButton, setActiveButton] = useState(1);
  const handleButtonClick = (index, item) => {
    setActiveButton(index);
    context.setButtonActive(item);
  };
  const location = useLocation();
  // console.log(props.data, "header12 data", transportType, activeButton);
  useEffect(() => {
    props.data.forEach((element, index) => {
      if (element.Code === transportType) setActiveButton(index);
    });
  }, []);
  return (
    <div
      className=" d-flex justify-content-between align-item-center"
      style={{ width: "100%" }}>
      {props.data.map((item, index) => {
        return (
          <Button
            className="font-shipment"
            key={index}
            style={{
              backgroundColor:
                activeButton === index ? props.bluecolor : props.widecolor,
              color: activeButton === index ? props.widecolor : props.bluecolor,

              width: "100%",
              height: "50px",
              marginLeft: "15px",
              borderRadius: "48px",
            }}
            onClick={() => {
              if (location.pathname.split("/").at(-1) === "createrates") {
                handleButtonClick(index, item);
              }
            }}
          >
            {item.Description}
            {activeButton === index ? (
              <img
                src={urlResolver(`assets/${item.blueimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            ) : (
              <img
                src={urlResolver(`assets/${item.wideimg}`)}
                style={{ width: "20px", height: "20px" }}
                alt="air"
              />
            )}
          </Button>
        );
      })}
    </div>
  ); 
};
export default CreateBtnMargin;
