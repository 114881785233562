import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import orderInstance from "../../api/orderInterceptor";
import { API_PATH } from "../../constant/apiPath";

const EditDetaisl = ({ selectedOrg, data, setData }) => {
  let tempData = { ...data };
  const handleAdd = (role) => {
    if (role === "Buyer") {
      tempData?.BuyerCollection.push({
        BuyerName: "",
        BuyerCode: "",
        IsBuyer: true,
      });
    } else if (role === "Supplier") {
      tempData?.SupplierCollection.push({
        SupplierCode: "",
        SupplierName: "",
        IsSupplier: "",
      });
    } else {
      tempData?.AgentCollection.push({
        AgentCode: "",
        AgentName: "",
        AgentType: "",
        IsAgent: "",
      });
    }
    setData(tempData);
  };
  const handleChange = (event, index, role, name) => {
    tempData[role][index][name] = event.target.value;
    setData(tempData);
  };

  // console.log("ORG CODES", data);
  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={12}>
          <Typography variant="overline" color={"primary"}>
            Add details
          </Typography>
        </Grid>

        <Grid item xs={12} className="d-flex justify-content-between">
          <Typography variant="overline" color={"primary"}>
            Buyer
          </Typography>
          <button
            className="download-report-button"
            onClick={() => {
              handleAdd("Buyer");
            }}
            style={{ height: "20px" }}
          >
            Add
          </button>
        </Grid>
        {data?.BuyerCollection.map((item, index) => {
          console.log(index);
          return (
            <>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  label="Buyer Name"
                  onChange={(event) => {
                    handleChange(event, index, "BuyerCollection", "BuyerName");
                  }}
                  value={data?.BuyerCollection[index]?.BuyerName}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  fullWidth
                  size="small"
                  label="Buyer Code"
                  value={data?.BuyerCollection[index]?.BuyerCode}
                  onChange={(event) => {
                    handleChange(event, index, "BuyerCollection", "BuyerCode");
                  }}

                  // placeholder="Select Org Code for name."
                />
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Typography variant="overline" color={"primary"}>
            Supplier
          </Typography>
          <button
            className="download-report-button"
            onClick={() => {
              handleAdd("Supplier");
            }}
            style={{ height: "20px" }}
          >
            Add
          </button>
        </Grid>

        {data?.SupplierCollection.map((item, index) => {
          return (
            <>
              <Grid item xs={6}>
                {" "}
                <TextField
                  fullWidth
                  size="small"
                  label="Supplier Name"
                  value={data?.SupplierCollection[index]?.SupplierName}
                  onChange={(event) => {
                    handleChange(
                      event,
                      index,
                      "SupplierCollection",
                      "SupplierName"
                    );
                  }}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TextField
                  fullWidth
                  size="small"
                  label="Supplier Code"
                  value={data?.SupplierCollection[index]?.SupplierCode}
                  onChange={(event) => {
                    handleChange(
                      event,
                      index,
                      "SupplierCollection",
                      "SupplierCode"
                    );
                  }}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
            </>
          );
        })}
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} className="d-flex justify-content-between">
          <Typography variant="overline" color={"primary"}>
            Agent
          </Typography>
          <button
            className="download-report-button"
            onClick={() => {
              handleAdd("Agent");
            }}
            style={{ height: "20px" }}
          >
            Add
          </button>
        </Grid>
        {data?.AgentCollection.map((item, index) => {
          return (
            <>
              <Grid item xs={4}>
                {" "}
                <TextField
                  fullWidth
                  size="small"
                  label="Agent Name"
                  onChange={(event) => {
                    handleChange(event, index, "AgentCollection", "AgentName");
                  }}
                  value={data?.AgentCollection[index]?.AgentName}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="small"
                  label="Agent Code"
                  value={data?.AgentCollection[index]?.AgentCode}
                  onChange={(event) => {
                    handleChange(event, index, "AgentCollection", "AgentCode");
                  }}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
              <Grid item xs={4}>
                {" "}
                <TextField
                  fullWidth
                  size="small"
                  label="Agent Type"
                  onChange={(event) => {
                    handleChange(event, index, "AgentCollection", "AgentType");
                  }}
                  value={data?.AgentCollection[index]?.AgentType}
                  // placeholder="Select Org Code for name."
                />
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default EditDetaisl;
